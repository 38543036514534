import React, {memo, useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {ConfigContext} from "../../../contexts/ConfigContext";
import {useDispatch, useSelector} from "react-redux";
import ReactFlow, {
    useEdgesState,
    useNodesState,
    Handle,
    addEdge,
    Controls,
    Background,
    applyNodeChanges,
    applyEdgeChanges,
    useReactFlow,
    ReactFlowProvider,
    getRectOfNodes, getTransformForBounds
} from "react-flow-renderer";
import nodeTypes, {edgeTypes, staticEdgeTypes, staticNodeTypes} from "../../../components/NodeFlow/NodeTypes";
import FlowBar from "../../OrgChart/FlowBar";
import {Button, Form} from "react-bootstrap";
import {API_SERVER} from "../../../config/constant";
import { Spinner } from 'react-bootstrap';

// import '@xyflow/react/dist/style.css'; 



const getInitialNodes = () => JSON.parse(localStorage.getItem('articleNodes')) || [
    { id: '1', type: 'customNode', position: { x: 255, y: 0 }, data: { id: '1', label: 'Are They A Good Employee?' } },
    { id: '2', type: 'decisionNode', position: { x: 105, y: 210 }, data: { id: '2', label: 'Train Them' } },
    { id: '3', type: 'decisionNode', position: { x: 405, y: 210 }, data: { id: '3', label: 'Fire Them' } },
];

const getInitialEdges = () => JSON.parse(localStorage.getItem('articleEdge')) || [
    {
        id: 'e1-2',
        source: '1',
        target: '2',
        type: 'customEdge',
        data: { label: 'Yes' },
    },
    {
        id: 'e1-3',
        source: '1',
        target: '3',
        type: 'customEdge',
        data: { label: 'No' },
    },
];

let id = 0;
const getId = (type, nodes) => {
    // Find the maximum id used so far for the given type
    const maxId = nodes.reduce((max, node) => {
        if (node.id.startsWith(type)) {
            const currentId = parseInt(node.id.replace(`${type}_`, ''), 10);
            return Math.max(max, isNaN(currentId) ? 0 : currentId);
        }
        return max;
    }, -1);
    // Return the next id
    return `${type}_${maxId + 1}_${id++}`;
};

function ArticleFlow({id, decision_chart, editmode=false}) {
    const [nodes, setNodes] = useState(getInitialNodes);
    const [edges, setEdges] = useState(getInitialEdges);
    const [userPrompt, setUserPrompt] = useState("");
    const reactFlowWrapper = useRef(null);

    console.log({id, decision_chart, editmode})

    console.log({nodes,edges})

    const [reactFlowInstance, setReactFlowInstance] = useState(null);
    const [isLoading, setIsLoading] = useState(false);


    const onDragStart = (event, nodeType) => {

        // console.log({event, nodeType})
        event.dataTransfer.setData('application/reactflow', nodeType);
        event.dataTransfer.effectAllowed = 'move';
    };

    useEffect(() => {
        if(decision_chart && decision_chart.edges && decision_chart.nodes){
        setEdges(decision_chart.edges)
        setNodes(decision_chart.nodes)
        }
    }, [decision_chart]);

    useEffect(() => {
        localStorage.setItem('articleNodes', JSON.stringify(nodes));
        localStorage.setItem('articleEdge', JSON.stringify(edges));
    }, [nodes, edges]);

    const onNodesChange = useCallback(
        (changes) => {
            const nodes = JSON.parse(localStorage.getItem('articleNodes'))
            // console.log({changes})
            setNodes((nds) => applyNodeChanges(changes, nodes))
        },
        [],
    );
    const onEdgesChange = useCallback(
        (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
        [],
    );

    const onConnect = useCallback(
        (params) => setEdges((eds) => addEdge({ ...params, type: 'customEdge' }, eds)),
        [],
    );


    const onDragOver = useCallback((event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);

    const onDrop = useCallback(
        (event) => {
            event.preventDefault();
    
            const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
            const type = event.dataTransfer.getData('application/reactflow');
    
            // check if the dropped element is valid
            if (typeof type === 'undefined' || !type) {
                return;
            }
    
            const position = reactFlowInstance.project({
                x: event.clientX - reactFlowBounds.left,
                y: event.clientY - reactFlowBounds.top,
            });
    
            // Adjust the position to center the node on the cursor
            const newNode = {
                id: getId(type, nodes),
                type,
                position: {
                    x: position.x - 90, // Assuming the node width is 180px
                    y: position.y - 100, // Assuming the node height is 44px
                },
                data: { id: getId(type, nodes), label: `New ${type}` },
            };
    
            setNodes((nds) => nds.concat(newNode));
        },
        [reactFlowInstance]
    );

    const onInit = (reactFlowInstance) => {
        setReactFlowInstance(reactFlowInstance);

    };

    const clearLocalStorage = () => {
        localStorage.removeItem('articleNodes');
        localStorage.removeItem('articleEdge');
        setNodes(getInitialNodes);
        setEdges(getInitialEdges);
    };


    async function generateDecisionChart(userPrompt) {
        const isCreateCommand = userPrompt.toLowerCase().includes('create');
        const structurePrompt = `
            You are an AI assistant designed to ${isCreateCommand ? 'create' : 'modify'} complex decision charts based on the user request.

            the resulting decision chart should include all possible questiosn and that might come up in order to make a well educaded decision/action
            
    
            Generate a decision chart with the following requirements:
            1. All non-final nodes must be questions.
            2. Final nodes must be actions or decisions.
            3. Every node must have at least two outgoing connections, except for final nodes.
            4. Edge labels should be varied and descriptive.
            5. The chart should be logical and relevant to the user's request.
    
            Respond with a JSON object containing 'nodes' and 'edges' arrays.
            Nodes should have id, type ('customNode' for questions, 'decisionNode' for actions), and data (with a 'label' field).
            Edges should have id, source, target, and data (with a 'label' field).
    
            Do not include any position information for the nodes.
    
            User request: ${userPrompt}
        `;
    
        const structure = await callChatGPT(structurePrompt);
        return structure;
    }
    
    async function callChatGPT(prompt) {
        try {
            const response = await fetch(`${API_SERVER}company/api/openai-proxy/`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    model: 'gpt-4o',
                    temperature: 0.3,
                    max_tokens: 4000,
                    messages: [{ role: 'user', content: prompt }],
                }),
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const data = await response.json();
    
            if (!data.choices || data.choices.length === 0) {
                throw new Error('No response from ChatGPT');
            }
    
            const content = data.choices[0].message.content.trim();
            
            // Try to parse the entire response first
            try {
                return JSON.parse(content);
            } catch (e) {
                // If that fails, try to extract JSON from the response
                const jsonMatch = content.match(/\{[\s\S]*\}/);
                if (!jsonMatch) {
                    throw new Error('No valid JSON found in ChatGPT response');
                }
                return JSON.parse(jsonMatch[0]);
            }
        } catch (error) {
            console.error("Error calling ChatGPT:", error);
            throw error;
        }
    }
    
    function validateChart(nodes, edges) {
        const errors = [];
        const warnings = [];
    
        // Check if every non-final node has at least two outgoing edges
       /*  nodes.forEach(node => {
            const outgoingEdges = edges.filter(edge => edge.source === node.id);
            if (node.type === 'customNode' && outgoingEdges.length < 2) {
                errors.push(`Node ${node.id} has only ${outgoingEdges.length} outgoing edge(s). At least 2 are required for non-final nodes.`);
            }
        }); */
    
        // Check if every edge has a label
        edges.forEach(edge => {
            if (!edge.data || !edge.data.label || edge.data.label.trim() === '') {
                errors.push(`Edge from node ${edge.source} to node ${edge.target} is missing a label`);
            }
        });
    
        // Check if non-final nodes are customNode type and final nodes are decisionNode type
        nodes.forEach(node => {
            const outgoingEdges = edges.filter(edge => edge.source === node.id);
            if (outgoingEdges.length > 0) {
                if (node.type !== 'customNode') {
                    errors.push(`Non-final node ${node.id} should have type "customNode", but has type "${node.type}"`);
                }
            } else {
                if (node.type !== 'decisionNode') {
                    errors.push(`Final node ${node.id} should have type "decisionNode", but has type "${node.type}"`);
                }
            }
        });
    
        return { errors, warnings };
    }
    
    function adjustNodePositions(nodes, edges) {
        // If all nodes already have valid positions, return as is
        const allHavePositions = nodes.every(node => 
            node.position && 
            typeof node.position.x === 'number' && 
            typeof node.position.y === 'number'
        );
        
        if (allHavePositions) {
            return nodes;
        }

        // Create a map to store node information
        const nodeMap = new Map(nodes.map(node => [node.id, { 
            ...node, 
            children: [], 
            parents: [], 
            level: 0,
            position: node.position || { x: 0, y: 0 } // Default position if none provided
        }]));

        // Build relationships
        edges.forEach(edge => {
            const sourceNode = nodeMap.get(edge.source);
            const targetNode = nodeMap.get(edge.target);
            if (sourceNode && targetNode) {
                sourceNode.children.push(targetNode);
                targetNode.parents.push(sourceNode);
            }
        });

        // Find root nodes (nodes with no parents)
        const rootNodes = Array.from(nodeMap.values()).filter(node => node.parents.length === 0);
        if (rootNodes.length === 0 && nodeMap.size > 0) {
            // If no root nodes found, use the first node as root
            rootNodes.push(nodeMap.values().next().value);
        }

        // Assign levels using BFS
        const queue = rootNodes.map(node => [node, 0]);
        const levelMap = new Map();
        const visited = new Set();

        while (queue.length > 0) {
            const [node, level] = queue.shift();
            if (visited.has(node.id)) continue;
            
            visited.add(node.id);
            node.level = level;
            if (!levelMap.has(level)) levelMap.set(level, []);
            levelMap.get(level).push(node);
            
            node.children.forEach(child => {
                if (!visited.has(child.id)) {
                    queue.push([child, level + 1]);
                }
            });
        }

        // Calculate optimal spacing based on number of nodes
        const totalNodes = nodes.length;
        const verticalSpacing = Math.max(200, Math.min(300, 800 / Math.sqrt(totalNodes)));
        const horizontalSpacing = Math.max(250, Math.min(400, 1000 / Math.sqrt(totalNodes)));

        // Position nodes level by level
        levelMap.forEach((nodesInLevel, level) => {
            const levelWidth = (nodesInLevel.length - 1) * horizontalSpacing;
            const startX = -levelWidth / 2;

            nodesInLevel.forEach((node, index) => {
                // Only set position if it wasn't provided
                if (!node.position || !node.position.x || !node.position.y) {
                    node.position = {
                        x: startX + (index * horizontalSpacing),
                        y: level * verticalSpacing
                    };
                }
            });
        });

        // Handle any orphaned nodes (not visited in BFS)
        let orphanLevel = levelMap.size;
        Array.from(nodeMap.values())
            .filter(node => !visited.has(node.id))
            .forEach((node, index) => {
                node.position = {
                    x: index * horizontalSpacing - ((nodes.length - visited.size) * horizontalSpacing) / 2,
                    y: orphanLevel * verticalSpacing
                };
            });

        // Convert back to array and ensure all nodes have positions
        return Array.from(nodeMap.values()).map(node => ({
            ...node,
            position: node.position
        }));
    }

    // Helper function to check if two line segments intersect
    function doLinesIntersect(x1, y1, x2, y2, x3, y3, x4, y4) {
        // Calculate the direction of the lines
        const uA = ((x4-x3)*(y1-y3) - (y4-y3)*(x1-x3)) / ((y4-y3)*(x2-x1) - (x4-x3)*(y2-y1));
        const uB = ((x2-x1)*(y1-y3) - (y2-y1)*(x1-x3)) / ((y4-y3)*(x2-x1) - (x4-x3)*(y2-y1));

        // If uA and uB are between 0-1, lines are colliding
        return (uA >= 0 && uA <= 1 && uB >= 0 && uB <= 1);
    }

    // Helper function to check if a line intersects with a rectangle
function lineIntersectsRectangle(lineStart, lineEnd, rect) {
    // Check if either end of the line is inside the rectangle
    if (pointInRectangle(lineStart, rect) || pointInRectangle(lineEnd, rect)) {
        return true;
    }

    // Check if the line intersects any of the rectangle's edges
    const rectEdges = [
        {start: {x: rect.left, y: rect.top}, end: {x: rect.right, y: rect.top}},
        {start: {x: rect.right, y: rect.top}, end: {x: rect.right, y: rect.bottom}},
        {start: {x: rect.right, y: rect.bottom}, end: {x: rect.left, y: rect.bottom}},
        {start: {x: rect.left, y: rect.bottom}, end: {x: rect.left, y: rect.top}}
    ];

    return rectEdges.some(edge => 
        doLinesIntersect(lineStart.x, lineStart.y, lineEnd.x, lineEnd.y, 
                         edge.start.x, edge.start.y, edge.end.x, edge.end.y)
    );
}

// Helper function to check if a point is inside a rectangle
function pointInRectangle(point, rect) {
    return point.x >= rect.left && point.x <= rect.right && 
           point.y >= rect.top && point.y <= rect.bottom;
}
    
async function fixChartErrors(chart, validationResult) {
    const { errors, warnings } = validationResult;
    const fixPrompt = `
        The following decision chart has some issues:
        ${JSON.stringify(chart)}
        
        Errors:
        ${errors.join('\n')}
        
        Warnings:
        ${warnings.join('\n')}
        
        Please fix ALL errors and as many warnings as possible. Return the corrected chart as a JSON object.
        Do not include any position information for the nodes.
    `;
    
    return await callChatGPT(fixPrompt);
}

const handleAIRequest = async () => {
    setIsLoading(true);
    let attempts = 0;
    const maxAttempts = 5;
    let finalChart;
    let validationResult;

    while (attempts < maxAttempts) {
        try {
            if (attempts === 0) {
                finalChart = await generateDecisionChart(userPrompt);
            } else {
                finalChart = await fixChartErrors(finalChart, validationResult);
            }
            
            validationResult = validateChart(finalChart.nodes, finalChart.edges);
            
            if (validationResult.errors.length === 0) {
                // Chart is valid, adjust positions and update state
                finalChart.nodes = adjustNodePositions(finalChart.nodes, finalChart.edges);
                setNodes(finalChart.nodes);
                setEdges(finalChart.edges);
                break;
            } else {
                console.error("Generated chart has issues:", validationResult);
                attempts++;
            }
        } catch (error) {
            console.error("Error generating decision chart:", error);
            attempts++;
        }
    }
    
    if (attempts >= maxAttempts) {
        console.error("Failed to generate a valid chart after multiple attempts");
        alert("Unable to generate a valid decision chart. Please try again or modify your request.");
    }
    
    setIsLoading(false);
};


    return (
        <div className="reactflow-wrapper" ref={reactFlowWrapper} style={{ height: 680 }}>
            {editmode && <div className='d-flex'>
            <Button className={'btn-sm'} onClick={clearLocalStorage} style={{ marginBottom: '10px' }}>reset</Button>

            <Button style={{ padding: 10, borderWidth:0, backgroundColor: '#ddd', color:'#151515', borderRadius: '5px' }}
                    onDragStart={(event) => onDragStart(event, 'customNode')}
                    draggable
                // onClick={() => onClickAddNode('positionNode')}
            >
                Drag To Add a New Question
            </Button>

            <Button style={{ padding: 10, backgroundColor: '#dddFFF', color:'#151515', clipPath: 'polygon(15px 0, calc(100% - 15px) 0, 100% 15px, 100% calc(100% - 15px), calc(100% - 15px) 100%, 15px 100%, 0 calc(100% - 15px), 0 15px)',
        }}
                    onDragStart={(event) => onDragStart(event, 'decisionNode')}
                    draggable
                // onClick={() => onClickAddNode('positionNode')}
            >
                Drag To Add a New decision/action
            </Button>

            <Form.Control
                type="text"
                placeholder="Enter your prompt here"
                value={userPrompt}
                onChange={(e) => setUserPrompt(e.target.value)}
                style={{ marginBottom: '10px' }}
            />
            <Button onClick={handleAIRequest} style={{ marginBottom: '10px' }} disabled={isLoading}>
            {isLoading ? (
                <>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    <span className="sr-only"> Generating...</span> 
                </>
            ) : (
                'Generate/Modify Chart'
            )}
        </Button>
        </div>}

            <ReactFlowProvider>
                <ReactFlow
                    style={{ width: '100%', height: '100%' }}
                    onDrop={onDrop}
                    onDragOver={onDragOver}
                    nodes={nodes}
                    onNodesChange={onNodesChange}
                    edges={edges}
                    onEdgesChange={onEdgesChange}
                    onInit={onInit}
                    onConnect={onConnect}
                    nodeTypes={editmode?nodeTypes:staticNodeTypes}
                    fitView
                    snapToGrid
                    snapGrid={[15,15]}
                    maxZoom={2}
                    minZoom={0.2}
                    edgeTypes={editmode?edgeTypes:staticEdgeTypes}
                    nodesDraggable={editmode} // Disable nodes dragging
                    nodesConnectable={editmode} // Disable nodes connecting
                    elementsSelectable={editmode}
                >
                    <Background />
                    <Controls />
                </ReactFlow>
            </ReactFlowProvider>

        </div>
    );
}

export default ArticleFlow;