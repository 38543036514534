import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Dropdown } from 'react-nested-dropdown';
import { Knowledge } from '../../services';
import 'react-nested-dropdown/dist/styles.css';
import CustomTooltip from '../CustomTooltip';
import { useSelector } from 'react-redux';

function renderCategoryOptions(category, prefix = '') {

    return (
        <>
            <option key={category.id} id={category.id} value={category.id}>
                {prefix}{category.category}
            </option>
            {category.subcategories?.map((subCategory) =>
                renderCategoryOptions(subCategory, `${prefix}${category.category} < `)
            )}
        </>
    );
}

export function ArticleCategorySelect({ article, setArticle, errors }) {
    const company = useSelector((state) => state.account.user?.company?.id);
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [showEdit, setShowEdit] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [rawCategories, setRawCategories] = useState([]);
    


    const findCategoryById = (id) => {
        for (const item of categories) {
            if (item.id === id) {
                return item;
            }
            if (item.items) {
                const found = findCategoryById(item.items, id);
                if (found) {
                    return found;
                }
            }
        }
        return null;
    }

    const getArticleCategories = async () => {
        setLoading(true);
        try {
            const data = await Knowledge.getArticleCategories({ paginate: false });
            data?.sort((a, b) => a.category.localeCompare(b.category));
            setCategories(formatCategories(data));
            setRawCategories(data);
            
            // Set initial selection if article has a category
            if (article?.article_category) {
                const initialLabel = findCategoryLabel(data, article.article_category);
                if (initialLabel) {
                    setSelectedCategory({id: article.article_category, category:initialLabel, parent: ''});
                }
            }
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
        setLoading(false);
    };

    const findCategoryLabel = (cats, targetId) => {
        for (const cat of cats) {
            if (cat.id === targetId) return cat.category;
            if (cat.subcategories?.length) {
                const found = findCategoryLabel(cat.subcategories, targetId);
                if (found) return found;
            }
        }
        return null;
    };

    const formatCategories = (cats) => {
        return cats.map(cat => ({
            label: cat.category,
            value: cat.id,
            parent: cat.parent,
            onSelect: () => handleSelect(cat.id, cat.category, cat.parent),
            items: cat.subcategories?.length ? formatCategories(cat.subcategories) : undefined,
            itemsContainerWidth: "200px"
        }));
    };

    const handleSelect = (categoryId, categoryLabel, categoryParent) => {
        setArticle(prev => ({ ...prev, article_category: categoryId }));
        setSelectedCategory({id: categoryId, category: categoryLabel, parent: categoryParent});
    };

    useEffect(() => {
        getArticleCategories();
    }, []);

    const handleUpdateCategory = async (e) => {
        setUpdating(true);
        e.preventDefault();
        const res = await Knowledge.patchArticleCategories(selectedCategory.id, {
            category: selectedCategory.category, 
            parent: selectedCategory.parent
        });
        
        setArticle((article) => ({ ...article, article_category: res.id }));
        
        // Refresh categories to ensure proper hierarchy
        await getArticleCategories();
        setShowEdit(false);
    };

    const handleCreateCategory = async (e) => {
        setUpdating(true);
        e.preventDefault();
        const data = {...selectedCategory, company: company};
        const res = await Knowledge.postArticleCategories(data);
        
        setArticle((article) => ({ ...article, article_category: res.id }));
        
        // Refresh categories to ensure proper hierarchy
        await getArticleCategories();
        setShowEdit(false);
    };

    const handleDeleteCategory = async (e) => {
        setUpdating(true);
        e.preventDefault();
        // Call the API to delete the selected category
        await Knowledge.deleteArticleCategories(selectedCategory.id);
        // Refresh the categories
        // getArticleCategories();
        setArticle((article) => ({ ...article, article_category: categories[0].id }));
        setCategories(categories.filter((category) => category.id !== selectedCategory.id));
        setRawCategories(rawCategories.filter((category) => category.id !== selectedCategory.id));
        // setSelectedCat(categories[0].category);
        setShowEdit(false);
    };

    const handleChangeCat = (e) => {
        console.log('handleChangeCat',e)
        // function to set the new category name on user input
        const value = e.target.value;
        const name = e.target.name;
        setSelectedCategory((cat) => ({ ...cat, [name]: value }));
    };

    console.log({selectedCategory})

    return (
        <div>
        <Form.Group>
            <Form.Label>Category</Form.Label>
            <div className="d-flex">
            <Form.Group controlId="articleCategory" className="w-100">
                <Dropdown 
                    items={categories}
                    containerWidth="100%"
                    className="custom-dropdown"
                    closeOnScroll={false}
                >
                    {({ isOpen, onClick }) => (
                        <button 
                            type="button" 
                            className="dropdown-trigger-btn"
                            onClick={onClick}
                            disabled={loading}
                        >
                            {loading ? "Loading..." : (selectedCategory.category || "Select Category")}
                            <i className={`feather icon-chevron-${isOpen ? 'up' : 'down'} ml-2`} />
                        </button>
                    )}
                </Dropdown>
                {errors?.article_category && (
                    <div className="invalid-feedback d-block">
                        Please select a category
                    </div>
                )}
                </Form.Group>
                
                
                                                   
                    <CustomTooltip title="Create an Article Category">
                        <Button className="btn btn-sm btn-icon btn-primary ml-2 mr-1" onClick={() => {
                            setSelectedCategory({id: '', category: '', parent: ''});
                            setShowEdit(true);
                            setIsEditMode(false);

                        }}>
                            <i className="feather icon-plus" />
                        </Button>
                    </CustomTooltip>
                    <CustomTooltip title="Edit Article Category">
                        <Button className="btn btn-sm btn-icon btn-primary mx-0" onClick={() => {
                            setShowEdit(true);
                            setIsEditMode(true);
                        }}>
                            <i className="feather icon-edit" />
                        </Button>
                    </CustomTooltip>
                </div>
            </Form.Group>

            <Modal show={showEdit} onHide={() => setShowEdit(false)}>
                 <Modal.Header closeButton>
                     <Modal.Title>{isEditMode ? 'Edit Category' : 'Add Category'}</Modal.Title>
                 </Modal.Header>
                 <Form onSubmit={isEditMode ? handleUpdateCategory : handleCreateCategory}>
                     <Modal.Body>
                        <Form.Group controlId="formBasicEmail">
                           <Form.Label>Category Name</Form.Label>
                           <Form.Control
                                name="category"
                                type="text"
                                value={selectedCategory?.category}
                                onChange={handleChangeCat}
                                placeholder="Enter new category name"
                            />
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Parent Category</Form.Label>

                            <Form.Control 
                                as="select" 
                                name="parent" 
                                value={selectedCategory?.parent} 
                                onChange={handleChangeCat}
                            >
                        {loading ? (
                            <option disabled={true} value={selectedCategory?.parent}>

                                Loading...
                            </option>
                        ) : rawCategories.length > 0 ? (
                            <>
                                <option key="none" id="none" value="">
                                    NONE
                                </option>
                                {rawCategories
                                    .filter((category) => category.id !== selectedCategory?.id)
                                    .map((category) => renderCategoryOptions(category))
                                }
                            </>
                        ) : (
                            <option id="PLACEHOLDER" disabled>
                                Please Add Your First Category
                            </option>
                        )}
                    </Form.Control>
                        </Form.Group>
                        
                    </Modal.Body>
                    <Modal.Footer>
                        {isEditMode &&
                            <Button className='btn-danger mr-auto' onClick={handleDeleteCategory} disabled={updating}>
                                {updating ? 'Deleting...' : 'Delete'}
                            </Button>
                        }
                        <Button variant="secondary" onClick={() => setShowEdit(false)}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit" disabled={updating}>
                            {updating ? 'Saving...' : 'Save'}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
</div>
         
    );
}
