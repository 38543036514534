import React, { useEffect, useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import CustomTooltip from '../../../../components/CustomTooltip';
import useGetSubscription from '../../../../hooks/useGetSubscription';
import { billingPageRoute } from '../../../../routes';
import ChatList from './ChatList';
import DropdownMenu from './DropdownMenu';
import NavCompanyInfo from './NavCompanyInfo';
import LightDark from './dark-light';
import NavShareModal from "./NavShareModal";
const NavRight = () => {
    const account = useSelector((state) => state.account);
    const { mySubscription } = useGetSubscription();

    console.log({account})

    const userInfo = {
        username: account?.user?.email, // REQUIRED, must be unique
        email: account?.user?.email,
        firstname: account?.user?.first_name,
        lastname: account?.user?.last_name,
        signUpAt: account?.user?.company?.company_registered_on, // maybe this should be for them individually
        role: account?.user?.user_type?.user_type,
        props: {
            premium: mySubscription?.subscription_plan,
            // active_features:account?.user?.company?.active_apps,
            company: account?.user?.company,
            role: account?.user?.user_type?.user_type,
            signUpAt: account?.user?.company?.company_registered_on, // Sign up date (yyyy-mm-ddThh:mm:ss)
            company_registered_on: account?.user?.company?.company_registered_on,
            user_is_admin: account?.user?.user_roles?.user_is_admin || false,
            user_is_kb_admin: account?.user?.user_roles?.user_is_kb_admin || false,
            user_is_profile_admin: account?.user?.user_roles?.user_is_profile_admin || false,
            user_is_pr_payer: account?.user?.user_roles?.user_is_pr_payer || false,
            user_is_pr_approver: account?.user?.user_roles?.user_is_pr_approver || false,
            user_is_attendance_admin: account?.user?.user_roles?.user_is_attendance_admin || false,
            user_is_request_admin: account?.user?.user_roles?.user_is_request_admin || false
        }
    };

    useEffect(() => {
        // Push the data into $productFruits
        window.$productFruits?.push(['init', 'O_cyAOQfUzUhImT7', 'en', userInfo]);
        // Initialize Clarity
        // Check if Clarity is available
        if (window.clarity) {
            // Set up user information when available
            if (account?.user) {
                window.clarity('identify', account.user.id.toString(), {
                    name: account.user.name,
                    email: account.user.email,
                    company: account.user?.company?.company_name,
                    // Add any other relevant user properties
                });
            }
        }

    }, [userInfo]);

    useEffect(() => {
        // Call gist.identify when the gist object is ready and the user information is available
        if (window.gist && typeof window.gist.identify === 'function') {
            window.gist.identify(account?.user?.id, {
                email: account?.user?.email,
                first_name: account?.user?.first_name,
                last_name: account?.user?.last_name,
                full_name: `${account?.user?.first_name} ${account?.user?.last_name}`,
                company: account?.user?.company?.company_name,
                company_sign_up: account?.user?.company?.company_registered_on,
                last_login: account?.user?.last_login,
                company_name: account?.user?.company?.company_name,
                premium: mySubscription?.subscription_plan,
                role: account?.user?.user_type?.user_type,
                user_is_admin: account?.user?.user_roles?.user_is_admin || false,
                user_is_kb_admin: account?.user?.user_roles?.user_is_kb_admin || false,
                user_is_profile_admin: account?.user?.user_roles?.user_is_profile_admin || false,
                user_is_pr_payer: account?.user?.user_roles?.user_is_pr_payer || false,
                user_is_pr_approver: account?.user?.user_roles?.user_is_pr_approver || false,
                user_is_attendance_admin: account?.user?.user_roles?.user_is_attendance_admin || false,
                user_is_request_admin: account?.user?.user_roles?.user_is_request_admin || false
            });
        }
    }, [account]); //

    const [listOpen, setListOpen] = useState(false);

    return (
        <React.Fragment>
            <ListGroup as="ul" bsPrefix=" " className="navbar-nav ml-auto" id="navbar-right">
                <ListGroup.Item as="li" bsPrefix=" " className="d-none d-md-inline">
                    {account?.user?.company?.company_information === ''?false:<NavCompanyInfo />}
                </ListGroup.Item>
                <ListGroup.Item as="li" bsPrefix=" " className="d-none d-md-inline">
                    <NavShareModal/>
                </ListGroup.Item>
                <ListGroup.Item as="li" bsPrefix=" " className="d-none d-sm-inline">
                    <NavLink to={billingPageRoute} className="text-success font-weight-light">
                        <i className="icon feather icon-loader" />
                        {mySubscription?.subscription_plan}
                    </NavLink>
                </ListGroup.Item>
                <ListGroup.Item as="li" bsPrefix=" ">
                    <NavLink to="/account/settings/edit-company/">
                        <CustomTooltip title='Your currently set timezone. Click to update.'>
                        <span>
                            <i className="icon feather icon-globe mr-1" />
                            <span className="d-none d-lg-none d-xl-inline">{account?.user?.timezone || 'No Timezone Set'}</span>
                        </span>
                    </CustomTooltip>
                    </NavLink>
                </ListGroup.Item>
                <ListGroup.Item as="li" bsPrefix=" " id="accessibilityWidget" className="mx-n1 cursor-pointer">
                    <CustomTooltip title={'Activate accessibility features like changing font options and colors for the vision impaired.'}>
                        <i className="fab fa-accessible-icon f-16" id="accessibilityWidget" />
                    </CustomTooltip>
                </ListGroup.Item>
                <ListGroup.Item as="li" bsPrefix=" " id="language" className="mx-n1">
                    <span id="language2" />
                </ListGroup.Item>
                <ListGroup.Item as="li" bsPrefix=" ">
                    <LightDark />
                </ListGroup.Item>

                <ListGroup.Item as="li" bsPrefix=" " className="mx-n1" width={30}>
                    <DropdownMenu />
                </ListGroup.Item>
            </ListGroup>
            <ChatList listOpen={listOpen} closed={() => setListOpen(false)} />
        </React.Fragment>
    );
};

export default NavRight;
