import React, { useEffect, useState } from 'react';
import {Button, Modal} from 'react-bootstrap';
import 'react-form-builder2/dist/app.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import subscriptionService from '../../../services/subscriptionService';
import { setUser } from '../../../store/accountActions';
import {InlineWidget, PopupButton, PopupWidget, useCalendlyEventListener} from "react-calendly";

export default function SelectSubscriptionPlan() {
    const user = useSelector((state) => state.account.user);
    const [isCreatingFreePlan, setIsCreatingFreePlan] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const userCount = new URLSearchParams(window.location.search).get('users') || '5';

    const subscriptionSuccessCallback = () => {
        // set user has_free_plan to true
        dispatch(setUser({ ...user, has_subscription_plan: true }));
        //todo turn this back on when we switch to react
        history.push('/');
        // window.location.href = '/';
    };

    useEffect(() => {
        const el = document.createElement('script');

        var customer = {
            first_name: user?.first_name,
            last_name: user?.last_name,
            email: user?.email.includes('+') ? '' : user?.email,
            company: user?.company.company_name,
            customer_id: user?.company.company_name,
            billing_address: { first_name: user?.first_name, last_name: user?.last_name, company: user?.company }
        };
        el.onload = () => {
            let cbInstance = window.Chargebee.init({
                site: process.env.REACT_APP_CHARGEBEEURL || 'humanagement-test'
                // domain: 'http://localhost:3000/auth/onboarding/'
            });
            window.Chargebee.registerAgain();

            cbInstance.setCheckoutCallbacks(function (cart) {
                // We set callbacks after the checkout process has been completed
                return {
                    close: function () {
                        // Called  once the checkout is closed by the end user.
                        toast.warning('Process Closed. Please select pricing plan to continue.');
                    },
                    success: function (hostedPageId) {
                        // Called after successful checkout completion.
                        subscriptionSuccessCallback();
                    },
                    error: function (error) {
                        // Called after successful checkout completion.
                        console.log('checkout error', error);
                        toast.error(`Unable to activate subscription plan: ${error}`);
                    }
                };
            });

            // this is where we're setting the customer details for sign up portal
            cbInstance.getCart().setCustomer(customer);
        };
        el.setAttribute('src', 'https://js.chargebee.com/v2/chargebee.js');

        // we set attribute to our site name
        el.setAttribute('data-cb-site', process.env.REACT_APP_CHARGEBEEURL || 'humanagement-test');
        document.head.appendChild(el);
    }, []);

    const handleCreateFreePlan = async () => {
        console.log('create free plan')
        setIsCreatingFreePlan(true);
        const data = await subscriptionService.createFreePlan();
        if (data) {
            subscriptionSuccessCallback();
        }
        setIsCreatingFreePlan(false);
    };

    const handleOpenModal = () => {
        setShowModal(true);
    };
    const handleCloseModal = () => {
        setShowModal(false);
    };

    useCalendlyEventListener({
    onEventScheduled: (e) => handleCreateFreePlan(),
  });

    useEffect(() => {
        const script = document.createElement('script');
        script.innerHTML = `
            window.uetq = window.uetq || [];
            window.uetq.push('set', {
                'pid': {
                    'em': 'contoso@example.com',
                    'ph': '+14250000000'
                }
            });
        `;
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script); // Clean up the script when the component unmounts
        };
    }, []);

    return (
        <>
            <div className="pricing8 py-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 text-center">
                            <h3 className="mb-3">Choose your Plan</h3>
                            <h6 className="subtitle font-weight-normal">We offer 100% satisfaction and customer support in all plans</h6>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-md-6 ml-auto pricing-box align-self-center">
                            <div className="card mb-4">
                                <div className="card-body p-4 text-center">
                                    <h5 className="font-weight-normal">Free Plan</h5>
                                    {/* <sup>$</sup>
                                    <span className="text-dark display-5">0</span>
                                    <h6 className="font-weight-light font-14">YEARLY</h6> */}
                                    <p className="mt-4">{process.env.REACT_APP_TRAINING === 'true'?
                                        "Limited to 1 User/Employee, good for building your knowledge base, courses and publishing a public KB"
                                        :"Limited to 1 User/Employee, good for building your knowledge base, courses, publishing a public KB and, allows up to 6 stats"}</p>
                                </div>
                                <Button className="mb-2" disabled={isCreatingFreePlan} onClick={handleCreateFreePlan}>
                                   {isCreatingFreePlan ? 'Enabling Plan...' : 'Choose Free Plan'}
                                </Button>
                                {/*<Modal show={showModal} style={{height:800}} size={'xl'} onHide={handleCloseModal}>*/}
                                {/*    <Modal.Header closeButton>*/}
                                {/*        <Modal.Title>Book A Meeting To Get Instant Access to Free Version</Modal.Title>*/}
                                {/*    </Modal.Header>*/}
                                {/*    <Modal.Body>*/}
                                {/*        <InlineWidget url="https://calendly.com/humanagement/live-demo" onEventScheduled={handleCreateFreePlan} />*/}
                                {/*    </Modal.Body>*/}
                                {/*</Modal>*/}
                                {/* <PopupButton
                                    rootElement={document.getElementById("root")}
                                    text="Choose Free Plan"
                                    className={"btn btn-primary mb-2 mx-4"}
                                    textColor="#ffffff"
                                    data-resize={true}
                                    resize={true}
                                    pageSettings={{
                                        backgroundColor: 'ffffff',
                                        hideEventTypeDetails: true,
                                        hideLandingPageDetails: true,
                                        primaryColor: '00a2ff',
                                        textColor: '4d5055',
                                        height: 1000,
                                        overflow: 'hidden',
                                    }}

                                    prefill={{
                                        email: user?.email,
                                        name: user?.first_name||false,
                                      }}
                                    color="#00a2ff"
                                    url="https://calendly.com/humanagement/onboarding"
                                    onEventScheduled={handleCreateFreePlan} />


                                {/*made this open calendly where it has to be selected or something to go on*/}
                                                                {/* <center className='mb-2'>
                                                                    Requires you to book a meeting
                                                                    <a className='cursor-pointer text-primary ml-1' onClick={handleCreateFreePlan}>Skip</a>
                                                                </center> */}
                                                                <center className='mb-2 small'>No Credit Card Required</center>

                            </div>
                        </div>

                        <div className="col-md-6 ml-auto pricing-box align-self-center">
                            <div className="card mb-4">
                                <div className="card-body p-4 text-center">
                                    <h5 className="font-weight-normal">Premium Plan</h5>
                                    {/* <sup>$</sup>
                                    <span className="text-dark display-5">69</span>
                                    <h6 className="font-weight-light font-14">MONTHLY</h6> */}
                                    <p className="mt-4">The ultimate plan with no limitations on features, users, employees or modules. You can add as many users, employees, and admins as you want.</p>
                                </div>
                                <Button
                                    className="mb-2 mx-4 text-white"
                                    href="javascript:void(0)"
                                    data-cb-type="checkout"
                                    data-cb-item-0={process.env.REACT_APP_TRAINING === 'true' ? "Training-Standard-USD-Monthly" : "Wisemen-Premium-USD-Monthly"}
                                    data-cb-item-1= {process.env.REACT_APP_TRAINING === 'true' ?"Users--USD-Monthly":"Users-USD-Monthly"}
                                    data-cb-item-1-quantity={userCount}
                                    disabled={isCreatingFreePlan}
                                >
                                    Choose 15-Day Free Trial
                                </Button>
                                <center className='mb-2 small'>Credit Card Required</center>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
