import React, { useEffect } from 'react';
import { Card, Modal } from 'react-bootstrap';
import ArticleForm from './ArticleForm';

export default function ArticleModal({ showCreate, setShowCreate, reload, setReload, callbackFunc }) {
    // useEffect(() => {
    //     setShowCreate(false);
    // }, [reload]);

    useEffect(() => {
        const stopPropagation = (event) => event.stopPropagation();
        const observer = new MutationObserver((mutationsList, observer) => {
            for (let mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    const inputs = document.querySelectorAll('.jodit-editor input:not(.Custom)');
                    inputs.forEach((input) => {
                        input.classList.add('Custom');
                        input.addEventListener('focus', stopPropagation, true);
                    });
                }
            }
        });

        observer.observe(document.body, { childList: true, subtree: true });

        return () => observer.disconnect();
    }, []);

    return (
        <Modal backdrop="static" enforceFocus={false} size={'lg'} show={showCreate} onHide={() => setShowCreate(false)}>
            <Modal.Header closeButton className="mb-2">
                <Modal.Title>Create Article</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Card>
                    <ArticleForm setReload={setReload} callbackFunc={callbackFunc} isCourseArticle />
                </Card>
            </Modal.Body>
        </Modal>
    );
}
